/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateDatumTypeInput = {
  id?: string | null,
  nodeTypeId: string,
  index: number,
  name: string,
  labels?: Array< LabelInfoInput > | null,
  type?: ValueType | null,
  unit?: string | null,
  digits?: number | null,
  valueMax?: number | null,
  valueMin?: number | null,
  limitHigh?: number | null,
  limitLow?: number | null,
  timeoutInSec?: number | null,
  permissions: Array< string >,
};

export type LabelInfoInput = {
  message: string,
  type: EventType,
};

export enum EventType {
  Failure = "Failure",
  Recovered = "Recovered",
  Information = "Information",
}


export enum ValueType {
  Number = "Number",
  Status = "Status",
  Longitude = "Longitude",
  Latitude = "Latitude",
  String = "String",
}


export type ModelDatumTypeConditionInput = {
  nodeTypeId?: ModelIDInput | null,
  index?: ModelIntInput | null,
  name?: ModelStringInput | null,
  type?: ModelValueTypeInput | null,
  unit?: ModelStringInput | null,
  digits?: ModelIntInput | null,
  valueMax?: ModelFloatInput | null,
  valueMin?: ModelFloatInput | null,
  limitHigh?: ModelFloatInput | null,
  limitLow?: ModelFloatInput | null,
  timeoutInSec?: ModelIntInput | null,
  and?: Array< ModelDatumTypeConditionInput | null > | null,
  or?: Array< ModelDatumTypeConditionInput | null > | null,
  not?: ModelDatumTypeConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelValueTypeInput = {
  eq?: ValueType | null,
  ne?: ValueType | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type DatumType = {
  __typename: "DatumType",
  id: string,
  nodeTypeId: string,
  index: number,
  name: string,
  labels?:  Array<LabelInfo > | null,
  type?: ValueType | null,
  unit?: string | null,
  digits?: number | null,
  valueMax?: number | null,
  valueMin?: number | null,
  limitHigh?: number | null,
  limitLow?: number | null,
  timeoutInSec?: number | null,
  permissions: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export type LabelInfo = {
  __typename: "LabelInfo",
  message: string,
  type: EventType,
};

export type UpdateDatumTypeInput = {
  id: string,
  nodeTypeId?: string | null,
  index?: number | null,
  name?: string | null,
  labels?: Array< LabelInfoInput > | null,
  type?: ValueType | null,
  unit?: string | null,
  digits?: number | null,
  valueMax?: number | null,
  valueMin?: number | null,
  limitHigh?: number | null,
  limitLow?: number | null,
  timeoutInSec?: number | null,
  permissions?: Array< string > | null,
};

export type DeleteDatumTypeInput = {
  id: string,
};

export type CreateEccMenuInput = {
  id?: string | null,
  accountId: string,
  path: string,
  name: string,
};

export type ModelEccMenuConditionInput = {
  accountId?: ModelStringInput | null,
  path?: ModelStringInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelEccMenuConditionInput | null > | null,
  or?: Array< ModelEccMenuConditionInput | null > | null,
  not?: ModelEccMenuConditionInput | null,
};

export type EccMenu = {
  __typename: "EccMenu",
  id: string,
  accountId: string,
  path: string,
  name: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateEccMenuInput = {
  id: string,
  accountId?: string | null,
  path?: string | null,
  name?: string | null,
};

export type DeleteEccMenuInput = {
  id: string,
};

export type CreateNodeTypeInput = {
  id?: string | null,
  name: string,
  typeIcon: Icon,
  imagePath?: string | null,
  mapImagePath?: string | null,
  mainDatumIndex?: number | null,
  reversedLabelColor?: boolean | null,
  permissions: Array< string >,
  inquiryChart?: Array< InquiryChartInput > | null,
};

export enum Icon {
  Office = "Office",
  Plant = "Plant",
  Equipment = "Equipment",
  Parts = "Parts",
}


export type InquiryChartInput = {
  title: string,
  inquiries: Array< InquiryInput >,
};

export type InquiryInput = {
  id: string,
  title: string,
  description: string,
  options: Array< InquiryOptionInput >,
};

export type InquiryOptionInput = {
  title: string,
  next: string,
};

export type ModelNodeTypeConditionInput = {
  name?: ModelStringInput | null,
  typeIcon?: ModelIconInput | null,
  imagePath?: ModelStringInput | null,
  mapImagePath?: ModelStringInput | null,
  mainDatumIndex?: ModelIntInput | null,
  reversedLabelColor?: ModelBooleanInput | null,
  and?: Array< ModelNodeTypeConditionInput | null > | null,
  or?: Array< ModelNodeTypeConditionInput | null > | null,
  not?: ModelNodeTypeConditionInput | null,
};

export type ModelIconInput = {
  eq?: Icon | null,
  ne?: Icon | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type NodeType = {
  __typename: "NodeType",
  id: string,
  name: string,
  typeIcon: Icon,
  imagePath?: string | null,
  mapImagePath?: string | null,
  mainDatumIndex?: number | null,
  reversedLabelColor?: boolean | null,
  permissions: Array< string >,
  inquiryChart?:  Array<InquiryChart > | null,
  createdAt: string,
  updatedAt: string,
};

export type InquiryChart = {
  __typename: "InquiryChart",
  title: string,
  inquiries:  Array<Inquiry >,
};

export type Inquiry = {
  __typename: "Inquiry",
  id: string,
  title: string,
  description: string,
  image?: InquiryImage | null,
  options:  Array<InquiryOption >,
};

export type InquiryImage = InquiryImageHref | InquiryImageS3


export type InquiryImageHref = {
  __typename: "InquiryImageHref",
  href: string,
};

export type InquiryImageS3 = {
  __typename: "InquiryImageS3",
  s3Key: string,
};

export type InquiryOption = {
  __typename: "InquiryOption",
  title: string,
  next: string,
};

export type UpdateNodeTypeInput = {
  id: string,
  name?: string | null,
  typeIcon?: Icon | null,
  imagePath?: string | null,
  mapImagePath?: string | null,
  mainDatumIndex?: number | null,
  reversedLabelColor?: boolean | null,
  permissions?: Array< string > | null,
  inquiryChart?: Array< InquiryChartInput > | null,
};

export type DeleteNodeTypeInput = {
  id: string,
};

export type CreateNodeInput = {
  id?: string | null,
  parentId: string,
  typeId: string,
  name: string,
  imagePath?: string | null,
  featureInfos: Array< FeatureInfoInput >,
  permissions: Array< string >,
  treeIndex?: number | null,
  account: string,
  isWarning?: boolean | null,
  specifications?: Array< SpecificationInput > | null,
  graphic?: GraphicInfoInput | null,
  camera?: CameraInfoInput | null,
};

export type FeatureInfoInput = {
  label: string,
  type: string,
};

export type SpecificationInput = {
  label: string,
  value: string,
};

export type GraphicInfoInput = {
  modelPath: string,
  cameraPositionX?: number | null,
  cameraPositionY?: number | null,
  cameraPositionZ?: number | null,
};

export type CameraInfoInput = {
  controllable?: boolean | null,
  statusDatumId?: string | null,
};

export type ModelNodeConditionInput = {
  parentId?: ModelIDInput | null,
  typeId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  imagePath?: ModelStringInput | null,
  treeIndex?: ModelIntInput | null,
  account?: ModelStringInput | null,
  isWarning?: ModelBooleanInput | null,
  and?: Array< ModelNodeConditionInput | null > | null,
  or?: Array< ModelNodeConditionInput | null > | null,
  not?: ModelNodeConditionInput | null,
};

export type Node = {
  __typename: "Node",
  id: string,
  parentId: string,
  typeId: string,
  name: string,
  imagePath?: string | null,
  featureInfos:  Array<FeatureInfo >,
  permissions: Array< string >,
  treeIndex?: number | null,
  account: string,
  isWarning?: boolean | null,
  specifications?:  Array<Specification > | null,
  graphic?: GraphicInfo | null,
  camera?: CameraInfo | null,
  createdAt: string,
  updatedAt: string,
};

export type FeatureInfo = {
  __typename: "FeatureInfo",
  label: string,
  type: string,
};

export type Specification = {
  __typename: "Specification",
  label: string,
  value: string,
};

export type GraphicInfo = {
  __typename: "GraphicInfo",
  modelPath: string,
  cameraPositionX?: number | null,
  cameraPositionY?: number | null,
  cameraPositionZ?: number | null,
};

export type CameraInfo = {
  __typename: "CameraInfo",
  controllable?: boolean | null,
  statusDatumId?: string | null,
};

export type DeleteNodeInput = {
  id: string,
};

export type CreateTenantInput = {
  id?: string | null,
  name: string,
  permissions: Array< string >,
  iotDevices: Array< IoTDeviceInput >,
};

export type IoTDeviceInput = {
  id: number,
  dataPoints: number,
  sendCycle: number,
  deployedAt?: string | null,
};

export type ModelTenantConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelTenantConditionInput | null > | null,
  or?: Array< ModelTenantConditionInput | null > | null,
  not?: ModelTenantConditionInput | null,
};

export type Tenant = {
  __typename: "Tenant",
  id: string,
  name: string,
  permissions: Array< string >,
  iotDevices:  Array<IoTDevice >,
  createdAt: string,
  updatedAt: string,
};

export type IoTDevice = {
  __typename: "IoTDevice",
  id: number,
  dataPoints: number,
  sendCycle: number,
  deployedAt?: string | null,
};

export type UpdateTenantInput = {
  id: string,
  name?: string | null,
  permissions?: Array< string > | null,
  iotDevices?: Array< IoTDeviceInput > | null,
};

export type DeleteTenantInput = {
  id: string,
};

export type CreateWorkHistoryInput = {
  id?: string | null,
  time: string,
  nodeId: string,
  workType: WorkType,
  workSummary: string,
  worker: string,
  workNumber?: string | null,
  positionX?: number | null,
  positionY?: number | null,
  positionZ?: number | null,
};

export enum WorkType {
  REPAIR = "REPAIR",
  INSPECTION = "INSPECTION",
  OTHER = "OTHER",
  MEMO = "MEMO",
}


export type ModelWorkHistoryConditionInput = {
  time?: ModelStringInput | null,
  nodeId?: ModelIDInput | null,
  workType?: ModelWorkTypeInput | null,
  workSummary?: ModelStringInput | null,
  worker?: ModelStringInput | null,
  workNumber?: ModelStringInput | null,
  positionX?: ModelFloatInput | null,
  positionY?: ModelFloatInput | null,
  positionZ?: ModelFloatInput | null,
  and?: Array< ModelWorkHistoryConditionInput | null > | null,
  or?: Array< ModelWorkHistoryConditionInput | null > | null,
  not?: ModelWorkHistoryConditionInput | null,
};

export type ModelWorkTypeInput = {
  eq?: WorkType | null,
  ne?: WorkType | null,
};

export type WorkHistory = {
  __typename: "WorkHistory",
  id: string,
  time: string,
  nodeId: string,
  workType: WorkType,
  workSummary: string,
  worker: string,
  workNumber?: string | null,
  positionX?: number | null,
  positionY?: number | null,
  positionZ?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateWorkHistoryInput = {
  id: string,
  time?: string | null,
  nodeId?: string | null,
  workType?: WorkType | null,
  workSummary?: string | null,
  worker?: string | null,
  workNumber?: string | null,
  positionX?: number | null,
  positionY?: number | null,
  positionZ?: number | null,
};

export type DeleteWorkHistoryInput = {
  id: string,
};

export type CreateAgvPositionInput = {
  id?: string | null,
  nodeId: string,
  imagePath?: string | null,
  displayName: string,
  floor?: string | null,
  layout?: string | null,
  paperSize?: string | null,
  scale?: string | null,
  revisedPriceX?: number | null,
  revisedPriceY?: number | null,
  theta?: number | null,
  originX?: number | null,
  originY?: number | null,
  type: agvPositionType,
  datumTypeIds?: Array< DatumTypeIdsTypeInput | null > | null,
};

export enum agvPositionType {
  Image = "Image",
  Data = "Data",
}


export type DatumTypeIdsTypeInput = {
  nodeId: string,
  xPositionDatumType: string,
  yPositionDatumType: string,
  floorDatumType: string,
  layoutDatumType: string,
};

export type ModelAgvPositionConditionInput = {
  nodeId?: ModelIDInput | null,
  imagePath?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  floor?: ModelStringInput | null,
  layout?: ModelStringInput | null,
  paperSize?: ModelStringInput | null,
  scale?: ModelStringInput | null,
  revisedPriceX?: ModelFloatInput | null,
  revisedPriceY?: ModelFloatInput | null,
  theta?: ModelFloatInput | null,
  originX?: ModelFloatInput | null,
  originY?: ModelFloatInput | null,
  type?: ModelagvPositionTypeInput | null,
  and?: Array< ModelAgvPositionConditionInput | null > | null,
  or?: Array< ModelAgvPositionConditionInput | null > | null,
  not?: ModelAgvPositionConditionInput | null,
};

export type ModelagvPositionTypeInput = {
  eq?: agvPositionType | null,
  ne?: agvPositionType | null,
};

export type AgvPosition = {
  __typename: "AgvPosition",
  id: string,
  nodeId: string,
  imagePath?: string | null,
  displayName: string,
  floor?: string | null,
  layout?: string | null,
  paperSize?: string | null,
  scale?: string | null,
  revisedPriceX?: number | null,
  revisedPriceY?: number | null,
  theta?: number | null,
  originX?: number | null,
  originY?: number | null,
  type: agvPositionType,
  datumTypeIds?:  Array<datumTypeIdsType | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type datumTypeIdsType = {
  __typename: "datumTypeIdsType",
  nodeId: string,
  xPositionDatumType: string,
  yPositionDatumType: string,
  floorDatumType: string,
  layoutDatumType: string,
};

export type UpdateAgvPositionInput = {
  id: string,
  nodeId?: string | null,
  imagePath?: string | null,
  displayName?: string | null,
  floor?: string | null,
  layout?: string | null,
  paperSize?: string | null,
  scale?: string | null,
  revisedPriceX?: number | null,
  revisedPriceY?: number | null,
  theta?: number | null,
  originX?: number | null,
  originY?: number | null,
  type?: agvPositionType | null,
  datumTypeIds?: Array< DatumTypeIdsTypeInput | null > | null,
};

export type DeleteAgvPositionInput = {
  id: string,
};

export type CreateDatumInput = {
  id?: string | null,
  nodeId: string,
  typeId: string,
  time: string,
  value?: string | null,
  status: number,
};

export type ModelDatumConditionInput = {
  nodeId?: ModelIDInput | null,
  typeId?: ModelIDInput | null,
  time?: ModelStringInput | null,
  value?: ModelStringInput | null,
  status?: ModelIntInput | null,
  and?: Array< ModelDatumConditionInput | null > | null,
  or?: Array< ModelDatumConditionInput | null > | null,
  not?: ModelDatumConditionInput | null,
};

export type Datum = {
  __typename: "Datum",
  id: string,
  nodeId: string,
  typeId: string,
  time: string,
  value?: string | null,
  status: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDatumInput = {
  id: string,
  nodeId?: string | null,
  typeId?: string | null,
  time?: string | null,
  value?: string | null,
  status?: number | null,
};

export type DeleteDatumInput = {
  id: string,
};

export type CreateEventlogInput = {
  id?: string | null,
  time: string,
  nodeId: string,
  datumId: string,
  datumName: string,
  type: EventType,
  event: string,
  permissions: Array< string >,
};

export type ModelEventlogConditionInput = {
  time?: ModelStringInput | null,
  nodeId?: ModelIDInput | null,
  datumId?: ModelIDInput | null,
  datumName?: ModelStringInput | null,
  type?: ModelEventTypeInput | null,
  event?: ModelStringInput | null,
  and?: Array< ModelEventlogConditionInput | null > | null,
  or?: Array< ModelEventlogConditionInput | null > | null,
  not?: ModelEventlogConditionInput | null,
};

export type ModelEventTypeInput = {
  eq?: EventType | null,
  ne?: EventType | null,
};

export type Eventlog = {
  __typename: "Eventlog",
  id: string,
  time: string,
  nodeId: string,
  datumId: string,
  datumName: string,
  type: EventType,
  event: string,
  permissions: Array< string >,
  createdAt: string,
  updatedAt: string,
};

export type UpdateEventlogInput = {
  id: string,
  time?: string | null,
  nodeId?: string | null,
  datumId?: string | null,
  datumName?: string | null,
  type?: EventType | null,
  event?: string | null,
  permissions?: Array< string > | null,
};

export type DeleteEventlogInput = {
  id: string,
};

export type CreateNodeSecretInput = {
  id?: string | null,
  camera?: CameraSecretInfoInput | null,
};

export type CameraSecretInfoInput = {
  origin: string,
  username: string,
  password: string,
};

export type ModelNodeSecretConditionInput = {
  and?: Array< ModelNodeSecretConditionInput | null > | null,
  or?: Array< ModelNodeSecretConditionInput | null > | null,
  not?: ModelNodeSecretConditionInput | null,
};

export type NodeSecret = {
  __typename: "NodeSecret",
  id: string,
  camera?: CameraSecretInfo | null,
  createdAt: string,
  updatedAt: string,
};

export type CameraSecretInfo = {
  __typename: "CameraSecretInfo",
  origin: string,
  username: string,
  password: string,
};

export type UpdateNodeSecretInput = {
  id: string,
  camera?: CameraSecretInfoInput | null,
};

export type DeleteNodeSecretInput = {
  id: string,
};

export type UpdateNodeInput = {
  id: string,
  parentId?: string | null,
  typeId?: string | null,
  name?: string | null,
  imagePath?: string | null,
  featureInfos?: Array< FeatureInfoInput > | null,
  permissions?: Array< string > | null,
  treeIndex?: number | null,
  account?: string | null,
  isWarning?: boolean | null,
  specifications?: Array< SpecificationInput > | null,
  graphic?: GraphicInfoInput | null,
  camera?: CameraInfoInput | null,
};

export type CreateOldDatumInput = {
  id?: string | null,
  datumId: string,
  time: string,
  value?: number | null,
  status: number,
};

export type ModelOldDatumConditionInput = {
  datumId?: ModelIDInput | null,
  time?: ModelStringInput | null,
  value?: ModelFloatInput | null,
  status?: ModelIntInput | null,
  and?: Array< ModelOldDatumConditionInput | null > | null,
  or?: Array< ModelOldDatumConditionInput | null > | null,
  not?: ModelOldDatumConditionInput | null,
};

export type OldDatum = {
  __typename: "OldDatum",
  id: string,
  datumId: string,
  time: string,
  value?: number | null,
  status: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateOldDatumInput = {
  id: string,
  datumId?: string | null,
  time?: string | null,
  value?: number | null,
  status?: number | null,
};

export type DeleteOldDatumInput = {
  id: string,
};

export type CreateOldDatumHourlyInput = {
  id?: string | null,
  datumId: string,
  time: string,
  value?: StatisticsInput | null,
  count: number,
  total: number,
};

export type StatisticsInput = {
  max: number,
  mean: number,
  mid: number,
  min: number,
};

export type ModelOldDatumHourlyConditionInput = {
  datumId?: ModelIDInput | null,
  time?: ModelStringInput | null,
  count?: ModelIntInput | null,
  total?: ModelIntInput | null,
  and?: Array< ModelOldDatumHourlyConditionInput | null > | null,
  or?: Array< ModelOldDatumHourlyConditionInput | null > | null,
  not?: ModelOldDatumHourlyConditionInput | null,
};

export type OldDatumHourly = {
  __typename: "OldDatumHourly",
  id: string,
  datumId: string,
  time: string,
  value?: Statistics | null,
  count: number,
  total: number,
  createdAt: string,
  updatedAt: string,
};

export type Statistics = {
  __typename: "Statistics",
  max: number,
  mean: number,
  mid: number,
  min: number,
};

export type UpdateOldDatumHourlyInput = {
  id: string,
  datumId?: string | null,
  time?: string | null,
  value?: StatisticsInput | null,
  count?: number | null,
  total?: number | null,
};

export type DeleteOldDatumHourlyInput = {
  id: string,
};

export type CreateOldDatumDailyInput = {
  id?: string | null,
  datumId: string,
  time: string,
  value?: StatisticsInput | null,
  count: number,
  total: number,
};

export type ModelOldDatumDailyConditionInput = {
  datumId?: ModelIDInput | null,
  time?: ModelStringInput | null,
  count?: ModelIntInput | null,
  total?: ModelIntInput | null,
  and?: Array< ModelOldDatumDailyConditionInput | null > | null,
  or?: Array< ModelOldDatumDailyConditionInput | null > | null,
  not?: ModelOldDatumDailyConditionInput | null,
};

export type OldDatumDaily = {
  __typename: "OldDatumDaily",
  id: string,
  datumId: string,
  time: string,
  value?: Statistics | null,
  count: number,
  total: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateOldDatumDailyInput = {
  id: string,
  datumId?: string | null,
  time?: string | null,
  value?: StatisticsInput | null,
  count?: number | null,
  total?: number | null,
};

export type DeleteOldDatumDailyInput = {
  id: string,
};

export type CreateOperatingRatioInput = {
  id?: string | null,
  nodeId: string,
  operatingDatumId: string,
  failureDatumId: string,
  operatingLabel: string,
  suspensionLabel: string,
  failureLabel: string,
};

export type ModelOperatingRatioConditionInput = {
  nodeId?: ModelIDInput | null,
  operatingDatumId?: ModelStringInput | null,
  failureDatumId?: ModelStringInput | null,
  operatingLabel?: ModelStringInput | null,
  suspensionLabel?: ModelStringInput | null,
  failureLabel?: ModelStringInput | null,
  and?: Array< ModelOperatingRatioConditionInput | null > | null,
  or?: Array< ModelOperatingRatioConditionInput | null > | null,
  not?: ModelOperatingRatioConditionInput | null,
};

export type OperatingRatio = {
  __typename: "OperatingRatio",
  id: string,
  nodeId: string,
  operatingDatumId: string,
  failureDatumId: string,
  operatingLabel: string,
  suspensionLabel: string,
  failureLabel: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateOperatingRatioInput = {
  id: string,
  nodeId?: string | null,
  operatingDatumId?: string | null,
  failureDatumId?: string | null,
  operatingLabel?: string | null,
  suspensionLabel?: string | null,
  failureLabel?: string | null,
};

export type DeleteOperatingRatioInput = {
  id: string,
};

export type GetCameraLiveStreamingUrlResult = {
  __typename: "GetCameraLiveStreamingUrlResult",
  url?: string | null,
  error?: string | null,
};

export type ModelEccMenuFilterInput = {
  id?: ModelIDInput | null,
  accountId?: ModelStringInput | null,
  path?: ModelStringInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelEccMenuFilterInput | null > | null,
  or?: Array< ModelEccMenuFilterInput | null > | null,
  not?: ModelEccMenuFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelEccMenuConnection = {
  __typename: "ModelEccMenuConnection",
  items:  Array<EccMenu | null >,
  nextToken?: string | null,
};

export type ModelNodeTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  typeIcon?: ModelIconInput | null,
  imagePath?: ModelStringInput | null,
  mapImagePath?: ModelStringInput | null,
  mainDatumIndex?: ModelIntInput | null,
  reversedLabelColor?: ModelBooleanInput | null,
  permissions?: ModelStringInput | null,
  and?: Array< ModelNodeTypeFilterInput | null > | null,
  or?: Array< ModelNodeTypeFilterInput | null > | null,
  not?: ModelNodeTypeFilterInput | null,
};

export type ModelNodeTypeConnection = {
  __typename: "ModelNodeTypeConnection",
  items:  Array<NodeType | null >,
  nextToken?: string | null,
};

export type ModelTenantFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  permissions?: ModelStringInput | null,
  and?: Array< ModelTenantFilterInput | null > | null,
  or?: Array< ModelTenantFilterInput | null > | null,
  not?: ModelTenantFilterInput | null,
};

export type ModelTenantConnection = {
  __typename: "ModelTenantConnection",
  items:  Array<Tenant | null >,
  nextToken?: string | null,
};

export type ModelWorkHistoryFilterInput = {
  id?: ModelIDInput | null,
  time?: ModelStringInput | null,
  nodeId?: ModelIDInput | null,
  workType?: ModelWorkTypeInput | null,
  workSummary?: ModelStringInput | null,
  worker?: ModelStringInput | null,
  workNumber?: ModelStringInput | null,
  positionX?: ModelFloatInput | null,
  positionY?: ModelFloatInput | null,
  positionZ?: ModelFloatInput | null,
  and?: Array< ModelWorkHistoryFilterInput | null > | null,
  or?: Array< ModelWorkHistoryFilterInput | null > | null,
  not?: ModelWorkHistoryFilterInput | null,
};

export type ModelWorkHistoryConnection = {
  __typename: "ModelWorkHistoryConnection",
  items:  Array<WorkHistory | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelAgvPositionFilterInput = {
  id?: ModelIDInput | null,
  nodeId?: ModelIDInput | null,
  imagePath?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  floor?: ModelStringInput | null,
  layout?: ModelStringInput | null,
  paperSize?: ModelStringInput | null,
  scale?: ModelStringInput | null,
  revisedPriceX?: ModelFloatInput | null,
  revisedPriceY?: ModelFloatInput | null,
  theta?: ModelFloatInput | null,
  originX?: ModelFloatInput | null,
  originY?: ModelFloatInput | null,
  type?: ModelagvPositionTypeInput | null,
  and?: Array< ModelAgvPositionFilterInput | null > | null,
  or?: Array< ModelAgvPositionFilterInput | null > | null,
  not?: ModelAgvPositionFilterInput | null,
};

export type ModelAgvPositionConnection = {
  __typename: "ModelAgvPositionConnection",
  items:  Array<AgvPosition | null >,
  nextToken?: string | null,
};

export type ModelDatumTypeFilterInput = {
  id?: ModelIDInput | null,
  nodeTypeId?: ModelIDInput | null,
  index?: ModelIntInput | null,
  name?: ModelStringInput | null,
  type?: ModelValueTypeInput | null,
  unit?: ModelStringInput | null,
  digits?: ModelIntInput | null,
  valueMax?: ModelFloatInput | null,
  valueMin?: ModelFloatInput | null,
  limitHigh?: ModelFloatInput | null,
  limitLow?: ModelFloatInput | null,
  timeoutInSec?: ModelIntInput | null,
  permissions?: ModelStringInput | null,
  and?: Array< ModelDatumTypeFilterInput | null > | null,
  or?: Array< ModelDatumTypeFilterInput | null > | null,
  not?: ModelDatumTypeFilterInput | null,
};

export type ModelDatumTypeConnection = {
  __typename: "ModelDatumTypeConnection",
  items:  Array<DatumType | null >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelDatumFilterInput = {
  id?: ModelIDInput | null,
  nodeId?: ModelIDInput | null,
  typeId?: ModelIDInput | null,
  time?: ModelStringInput | null,
  value?: ModelStringInput | null,
  status?: ModelIntInput | null,
  and?: Array< ModelDatumFilterInput | null > | null,
  or?: Array< ModelDatumFilterInput | null > | null,
  not?: ModelDatumFilterInput | null,
};

export type ModelDatumConnection = {
  __typename: "ModelDatumConnection",
  items:  Array<Datum | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelEventlogFilterInput = {
  id?: ModelIDInput | null,
  time?: ModelStringInput | null,
  nodeId?: ModelIDInput | null,
  datumId?: ModelIDInput | null,
  datumName?: ModelStringInput | null,
  type?: ModelEventTypeInput | null,
  event?: ModelStringInput | null,
  permissions?: ModelStringInput | null,
  and?: Array< ModelEventlogFilterInput | null > | null,
  or?: Array< ModelEventlogFilterInput | null > | null,
  not?: ModelEventlogFilterInput | null,
};

export type ModelEventlogConnection = {
  __typename: "ModelEventlogConnection",
  items:  Array<Eventlog | null >,
  nextToken?: string | null,
};

export type ModelNodeSecretFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelNodeSecretFilterInput | null > | null,
  or?: Array< ModelNodeSecretFilterInput | null > | null,
  not?: ModelNodeSecretFilterInput | null,
};

export type ModelNodeSecretConnection = {
  __typename: "ModelNodeSecretConnection",
  items:  Array<NodeSecret | null >,
  nextToken?: string | null,
};

export type ModelNodeFilterInput = {
  id?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  typeId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  imagePath?: ModelStringInput | null,
  permissions?: ModelStringInput | null,
  treeIndex?: ModelIntInput | null,
  account?: ModelStringInput | null,
  isWarning?: ModelBooleanInput | null,
  and?: Array< ModelNodeFilterInput | null > | null,
  or?: Array< ModelNodeFilterInput | null > | null,
  not?: ModelNodeFilterInput | null,
};

export type ModelNodeConnection = {
  __typename: "ModelNodeConnection",
  items:  Array<Node | null >,
  nextToken?: string | null,
};

export type ModelOldDatumFilterInput = {
  id?: ModelIDInput | null,
  datumId?: ModelIDInput | null,
  time?: ModelStringInput | null,
  value?: ModelFloatInput | null,
  status?: ModelIntInput | null,
  and?: Array< ModelOldDatumFilterInput | null > | null,
  or?: Array< ModelOldDatumFilterInput | null > | null,
  not?: ModelOldDatumFilterInput | null,
};

export type ModelOldDatumConnection = {
  __typename: "ModelOldDatumConnection",
  items:  Array<OldDatum | null >,
  nextToken?: string | null,
};

export type ModelOldDatumHourlyFilterInput = {
  id?: ModelIDInput | null,
  datumId?: ModelIDInput | null,
  time?: ModelStringInput | null,
  count?: ModelIntInput | null,
  total?: ModelIntInput | null,
  and?: Array< ModelOldDatumHourlyFilterInput | null > | null,
  or?: Array< ModelOldDatumHourlyFilterInput | null > | null,
  not?: ModelOldDatumHourlyFilterInput | null,
};

export type ModelOldDatumHourlyConnection = {
  __typename: "ModelOldDatumHourlyConnection",
  items:  Array<OldDatumHourly | null >,
  nextToken?: string | null,
};

export type ModelOldDatumDailyFilterInput = {
  id?: ModelIDInput | null,
  datumId?: ModelIDInput | null,
  time?: ModelStringInput | null,
  count?: ModelIntInput | null,
  total?: ModelIntInput | null,
  and?: Array< ModelOldDatumDailyFilterInput | null > | null,
  or?: Array< ModelOldDatumDailyFilterInput | null > | null,
  not?: ModelOldDatumDailyFilterInput | null,
};

export type ModelOldDatumDailyConnection = {
  __typename: "ModelOldDatumDailyConnection",
  items:  Array<OldDatumDaily | null >,
  nextToken?: string | null,
};

export type ModelOperatingRatioFilterInput = {
  id?: ModelIDInput | null,
  nodeId?: ModelIDInput | null,
  operatingDatumId?: ModelStringInput | null,
  failureDatumId?: ModelStringInput | null,
  operatingLabel?: ModelStringInput | null,
  suspensionLabel?: ModelStringInput | null,
  failureLabel?: ModelStringInput | null,
  and?: Array< ModelOperatingRatioFilterInput | null > | null,
  or?: Array< ModelOperatingRatioFilterInput | null > | null,
  not?: ModelOperatingRatioFilterInput | null,
};

export type ModelOperatingRatioConnection = {
  __typename: "ModelOperatingRatioConnection",
  items:  Array<OperatingRatio | null >,
  nextToken?: string | null,
};

export type CreateDatumTypeMutationVariables = {
  input: CreateDatumTypeInput,
  condition?: ModelDatumTypeConditionInput | null,
};

export type CreateDatumTypeMutation = {
  createDatumType?:  {
    __typename: "DatumType",
    id: string,
    nodeTypeId: string,
    index: number,
    name: string,
    labels?:  Array< {
      __typename: "LabelInfo",
      message: string,
      type: EventType,
    } > | null,
    type?: ValueType | null,
    unit?: string | null,
    digits?: number | null,
    valueMax?: number | null,
    valueMin?: number | null,
    limitHigh?: number | null,
    limitLow?: number | null,
    timeoutInSec?: number | null,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDatumTypeMutationVariables = {
  input: UpdateDatumTypeInput,
  condition?: ModelDatumTypeConditionInput | null,
};

export type UpdateDatumTypeMutation = {
  updateDatumType?:  {
    __typename: "DatumType",
    id: string,
    nodeTypeId: string,
    index: number,
    name: string,
    labels?:  Array< {
      __typename: "LabelInfo",
      message: string,
      type: EventType,
    } > | null,
    type?: ValueType | null,
    unit?: string | null,
    digits?: number | null,
    valueMax?: number | null,
    valueMin?: number | null,
    limitHigh?: number | null,
    limitLow?: number | null,
    timeoutInSec?: number | null,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDatumTypeMutationVariables = {
  input: DeleteDatumTypeInput,
  condition?: ModelDatumTypeConditionInput | null,
};

export type DeleteDatumTypeMutation = {
  deleteDatumType?:  {
    __typename: "DatumType",
    id: string,
    nodeTypeId: string,
    index: number,
    name: string,
    labels?:  Array< {
      __typename: "LabelInfo",
      message: string,
      type: EventType,
    } > | null,
    type?: ValueType | null,
    unit?: string | null,
    digits?: number | null,
    valueMax?: number | null,
    valueMin?: number | null,
    limitHigh?: number | null,
    limitLow?: number | null,
    timeoutInSec?: number | null,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEccMenuMutationVariables = {
  input: CreateEccMenuInput,
  condition?: ModelEccMenuConditionInput | null,
};

export type CreateEccMenuMutation = {
  createEccMenu?:  {
    __typename: "EccMenu",
    id: string,
    accountId: string,
    path: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEccMenuMutationVariables = {
  input: UpdateEccMenuInput,
  condition?: ModelEccMenuConditionInput | null,
};

export type UpdateEccMenuMutation = {
  updateEccMenu?:  {
    __typename: "EccMenu",
    id: string,
    accountId: string,
    path: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEccMenuMutationVariables = {
  input: DeleteEccMenuInput,
  condition?: ModelEccMenuConditionInput | null,
};

export type DeleteEccMenuMutation = {
  deleteEccMenu?:  {
    __typename: "EccMenu",
    id: string,
    accountId: string,
    path: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNodeTypeMutationVariables = {
  input: CreateNodeTypeInput,
  condition?: ModelNodeTypeConditionInput | null,
};

export type CreateNodeTypeMutation = {
  createNodeType?:  {
    __typename: "NodeType",
    id: string,
    name: string,
    typeIcon: Icon,
    imagePath?: string | null,
    mapImagePath?: string | null,
    mainDatumIndex?: number | null,
    reversedLabelColor?: boolean | null,
    permissions: Array< string >,
    inquiryChart?:  Array< {
      __typename: "InquiryChart",
      title: string,
      inquiries:  Array< {
        __typename: "Inquiry",
        id: string,
        title: string,
        description: string,
        image: ( {
            __typename: "InquiryImageHref",
            href: string,
          } | {
            __typename: "InquiryImageS3",
            s3Key: string,
          }
        ) | null,
        options:  Array< {
          __typename: "InquiryOption",
          title: string,
          next: string,
        } >,
      } >,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNodeTypeMutationVariables = {
  input: UpdateNodeTypeInput,
  condition?: ModelNodeTypeConditionInput | null,
};

export type UpdateNodeTypeMutation = {
  updateNodeType?:  {
    __typename: "NodeType",
    id: string,
    name: string,
    typeIcon: Icon,
    imagePath?: string | null,
    mapImagePath?: string | null,
    mainDatumIndex?: number | null,
    reversedLabelColor?: boolean | null,
    permissions: Array< string >,
    inquiryChart?:  Array< {
      __typename: "InquiryChart",
      title: string,
      inquiries:  Array< {
        __typename: "Inquiry",
        id: string,
        title: string,
        description: string,
        image: ( {
            __typename: "InquiryImageHref",
            href: string,
          } | {
            __typename: "InquiryImageS3",
            s3Key: string,
          }
        ) | null,
        options:  Array< {
          __typename: "InquiryOption",
          title: string,
          next: string,
        } >,
      } >,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNodeTypeMutationVariables = {
  input: DeleteNodeTypeInput,
  condition?: ModelNodeTypeConditionInput | null,
};

export type DeleteNodeTypeMutation = {
  deleteNodeType?:  {
    __typename: "NodeType",
    id: string,
    name: string,
    typeIcon: Icon,
    imagePath?: string | null,
    mapImagePath?: string | null,
    mainDatumIndex?: number | null,
    reversedLabelColor?: boolean | null,
    permissions: Array< string >,
    inquiryChart?:  Array< {
      __typename: "InquiryChart",
      title: string,
      inquiries:  Array< {
        __typename: "Inquiry",
        id: string,
        title: string,
        description: string,
        image: ( {
            __typename: "InquiryImageHref",
            href: string,
          } | {
            __typename: "InquiryImageS3",
            s3Key: string,
          }
        ) | null,
        options:  Array< {
          __typename: "InquiryOption",
          title: string,
          next: string,
        } >,
      } >,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNodeMutationVariables = {
  input: CreateNodeInput,
  condition?: ModelNodeConditionInput | null,
};

export type CreateNodeMutation = {
  createNode?:  {
    __typename: "Node",
    id: string,
    parentId: string,
    typeId: string,
    name: string,
    imagePath?: string | null,
    featureInfos:  Array< {
      __typename: "FeatureInfo",
      label: string,
      type: string,
    } >,
    permissions: Array< string >,
    treeIndex?: number | null,
    account: string,
    isWarning?: boolean | null,
    specifications?:  Array< {
      __typename: "Specification",
      label: string,
      value: string,
    } > | null,
    graphic?:  {
      __typename: "GraphicInfo",
      modelPath: string,
      cameraPositionX?: number | null,
      cameraPositionY?: number | null,
      cameraPositionZ?: number | null,
    } | null,
    camera?:  {
      __typename: "CameraInfo",
      controllable?: boolean | null,
      statusDatumId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNodeMutationVariables = {
  input: DeleteNodeInput,
  condition?: ModelNodeConditionInput | null,
};

export type DeleteNodeMutation = {
  deleteNode?:  {
    __typename: "Node",
    id: string,
    parentId: string,
    typeId: string,
    name: string,
    imagePath?: string | null,
    featureInfos:  Array< {
      __typename: "FeatureInfo",
      label: string,
      type: string,
    } >,
    permissions: Array< string >,
    treeIndex?: number | null,
    account: string,
    isWarning?: boolean | null,
    specifications?:  Array< {
      __typename: "Specification",
      label: string,
      value: string,
    } > | null,
    graphic?:  {
      __typename: "GraphicInfo",
      modelPath: string,
      cameraPositionX?: number | null,
      cameraPositionY?: number | null,
      cameraPositionZ?: number | null,
    } | null,
    camera?:  {
      __typename: "CameraInfo",
      controllable?: boolean | null,
      statusDatumId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTenantMutationVariables = {
  input: CreateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CreateTenantMutation = {
  createTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    permissions: Array< string >,
    iotDevices:  Array< {
      __typename: "IoTDevice",
      id: number,
      dataPoints: number,
      sendCycle: number,
      deployedAt?: string | null,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTenantMutationVariables = {
  input: UpdateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type UpdateTenantMutation = {
  updateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    permissions: Array< string >,
    iotDevices:  Array< {
      __typename: "IoTDevice",
      id: number,
      dataPoints: number,
      sendCycle: number,
      deployedAt?: string | null,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTenantMutationVariables = {
  input: DeleteTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type DeleteTenantMutation = {
  deleteTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    permissions: Array< string >,
    iotDevices:  Array< {
      __typename: "IoTDevice",
      id: number,
      dataPoints: number,
      sendCycle: number,
      deployedAt?: string | null,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWorkHistoryMutationVariables = {
  input: CreateWorkHistoryInput,
  condition?: ModelWorkHistoryConditionInput | null,
};

export type CreateWorkHistoryMutation = {
  createWorkHistory?:  {
    __typename: "WorkHistory",
    id: string,
    time: string,
    nodeId: string,
    workType: WorkType,
    workSummary: string,
    worker: string,
    workNumber?: string | null,
    positionX?: number | null,
    positionY?: number | null,
    positionZ?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWorkHistoryMutationVariables = {
  input: UpdateWorkHistoryInput,
  condition?: ModelWorkHistoryConditionInput | null,
};

export type UpdateWorkHistoryMutation = {
  updateWorkHistory?:  {
    __typename: "WorkHistory",
    id: string,
    time: string,
    nodeId: string,
    workType: WorkType,
    workSummary: string,
    worker: string,
    workNumber?: string | null,
    positionX?: number | null,
    positionY?: number | null,
    positionZ?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWorkHistoryMutationVariables = {
  input: DeleteWorkHistoryInput,
  condition?: ModelWorkHistoryConditionInput | null,
};

export type DeleteWorkHistoryMutation = {
  deleteWorkHistory?:  {
    __typename: "WorkHistory",
    id: string,
    time: string,
    nodeId: string,
    workType: WorkType,
    workSummary: string,
    worker: string,
    workNumber?: string | null,
    positionX?: number | null,
    positionY?: number | null,
    positionZ?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAgvPositionMutationVariables = {
  input: CreateAgvPositionInput,
  condition?: ModelAgvPositionConditionInput | null,
};

export type CreateAgvPositionMutation = {
  createAgvPosition?:  {
    __typename: "AgvPosition",
    id: string,
    nodeId: string,
    imagePath?: string | null,
    displayName: string,
    floor?: string | null,
    layout?: string | null,
    paperSize?: string | null,
    scale?: string | null,
    revisedPriceX?: number | null,
    revisedPriceY?: number | null,
    theta?: number | null,
    originX?: number | null,
    originY?: number | null,
    type: agvPositionType,
    datumTypeIds?:  Array< {
      __typename: "datumTypeIdsType",
      nodeId: string,
      xPositionDatumType: string,
      yPositionDatumType: string,
      floorDatumType: string,
      layoutDatumType: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAgvPositionMutationVariables = {
  input: UpdateAgvPositionInput,
  condition?: ModelAgvPositionConditionInput | null,
};

export type UpdateAgvPositionMutation = {
  updateAgvPosition?:  {
    __typename: "AgvPosition",
    id: string,
    nodeId: string,
    imagePath?: string | null,
    displayName: string,
    floor?: string | null,
    layout?: string | null,
    paperSize?: string | null,
    scale?: string | null,
    revisedPriceX?: number | null,
    revisedPriceY?: number | null,
    theta?: number | null,
    originX?: number | null,
    originY?: number | null,
    type: agvPositionType,
    datumTypeIds?:  Array< {
      __typename: "datumTypeIdsType",
      nodeId: string,
      xPositionDatumType: string,
      yPositionDatumType: string,
      floorDatumType: string,
      layoutDatumType: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAgvPositionMutationVariables = {
  input: DeleteAgvPositionInput,
  condition?: ModelAgvPositionConditionInput | null,
};

export type DeleteAgvPositionMutation = {
  deleteAgvPosition?:  {
    __typename: "AgvPosition",
    id: string,
    nodeId: string,
    imagePath?: string | null,
    displayName: string,
    floor?: string | null,
    layout?: string | null,
    paperSize?: string | null,
    scale?: string | null,
    revisedPriceX?: number | null,
    revisedPriceY?: number | null,
    theta?: number | null,
    originX?: number | null,
    originY?: number | null,
    type: agvPositionType,
    datumTypeIds?:  Array< {
      __typename: "datumTypeIdsType",
      nodeId: string,
      xPositionDatumType: string,
      yPositionDatumType: string,
      floorDatumType: string,
      layoutDatumType: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDatumMutationVariables = {
  input: CreateDatumInput,
  condition?: ModelDatumConditionInput | null,
};

export type CreateDatumMutation = {
  createDatum?:  {
    __typename: "Datum",
    id: string,
    nodeId: string,
    typeId: string,
    time: string,
    value?: string | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDatumMutationVariables = {
  input: UpdateDatumInput,
  condition?: ModelDatumConditionInput | null,
};

export type UpdateDatumMutation = {
  updateDatum?:  {
    __typename: "Datum",
    id: string,
    nodeId: string,
    typeId: string,
    time: string,
    value?: string | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDatumMutationVariables = {
  input: DeleteDatumInput,
  condition?: ModelDatumConditionInput | null,
};

export type DeleteDatumMutation = {
  deleteDatum?:  {
    __typename: "Datum",
    id: string,
    nodeId: string,
    typeId: string,
    time: string,
    value?: string | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEventlogMutationVariables = {
  input: CreateEventlogInput,
  condition?: ModelEventlogConditionInput | null,
};

export type CreateEventlogMutation = {
  createEventlog?:  {
    __typename: "Eventlog",
    id: string,
    time: string,
    nodeId: string,
    datumId: string,
    datumName: string,
    type: EventType,
    event: string,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEventlogMutationVariables = {
  input: UpdateEventlogInput,
  condition?: ModelEventlogConditionInput | null,
};

export type UpdateEventlogMutation = {
  updateEventlog?:  {
    __typename: "Eventlog",
    id: string,
    time: string,
    nodeId: string,
    datumId: string,
    datumName: string,
    type: EventType,
    event: string,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEventlogMutationVariables = {
  input: DeleteEventlogInput,
  condition?: ModelEventlogConditionInput | null,
};

export type DeleteEventlogMutation = {
  deleteEventlog?:  {
    __typename: "Eventlog",
    id: string,
    time: string,
    nodeId: string,
    datumId: string,
    datumName: string,
    type: EventType,
    event: string,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNodeSecretMutationVariables = {
  input: CreateNodeSecretInput,
  condition?: ModelNodeSecretConditionInput | null,
};

export type CreateNodeSecretMutation = {
  createNodeSecret?:  {
    __typename: "NodeSecret",
    id: string,
    camera?:  {
      __typename: "CameraSecretInfo",
      origin: string,
      username: string,
      password: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNodeSecretMutationVariables = {
  input: UpdateNodeSecretInput,
  condition?: ModelNodeSecretConditionInput | null,
};

export type UpdateNodeSecretMutation = {
  updateNodeSecret?:  {
    __typename: "NodeSecret",
    id: string,
    camera?:  {
      __typename: "CameraSecretInfo",
      origin: string,
      username: string,
      password: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNodeSecretMutationVariables = {
  input: DeleteNodeSecretInput,
  condition?: ModelNodeSecretConditionInput | null,
};

export type DeleteNodeSecretMutation = {
  deleteNodeSecret?:  {
    __typename: "NodeSecret",
    id: string,
    camera?:  {
      __typename: "CameraSecretInfo",
      origin: string,
      username: string,
      password: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNodeMutationVariables = {
  input: UpdateNodeInput,
  condition?: ModelNodeConditionInput | null,
};

export type UpdateNodeMutation = {
  updateNode?:  {
    __typename: "Node",
    id: string,
    parentId: string,
    typeId: string,
    name: string,
    imagePath?: string | null,
    featureInfos:  Array< {
      __typename: "FeatureInfo",
      label: string,
      type: string,
    } >,
    permissions: Array< string >,
    treeIndex?: number | null,
    account: string,
    isWarning?: boolean | null,
    specifications?:  Array< {
      __typename: "Specification",
      label: string,
      value: string,
    } > | null,
    graphic?:  {
      __typename: "GraphicInfo",
      modelPath: string,
      cameraPositionX?: number | null,
      cameraPositionY?: number | null,
      cameraPositionZ?: number | null,
    } | null,
    camera?:  {
      __typename: "CameraInfo",
      controllable?: boolean | null,
      statusDatumId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOldDatumMutationVariables = {
  input: CreateOldDatumInput,
  condition?: ModelOldDatumConditionInput | null,
};

export type CreateOldDatumMutation = {
  createOldDatum?:  {
    __typename: "OldDatum",
    id: string,
    datumId: string,
    time: string,
    value?: number | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOldDatumMutationVariables = {
  input: UpdateOldDatumInput,
  condition?: ModelOldDatumConditionInput | null,
};

export type UpdateOldDatumMutation = {
  updateOldDatum?:  {
    __typename: "OldDatum",
    id: string,
    datumId: string,
    time: string,
    value?: number | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOldDatumMutationVariables = {
  input: DeleteOldDatumInput,
  condition?: ModelOldDatumConditionInput | null,
};

export type DeleteOldDatumMutation = {
  deleteOldDatum?:  {
    __typename: "OldDatum",
    id: string,
    datumId: string,
    time: string,
    value?: number | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOldDatumHourlyMutationVariables = {
  input: CreateOldDatumHourlyInput,
  condition?: ModelOldDatumHourlyConditionInput | null,
};

export type CreateOldDatumHourlyMutation = {
  createOldDatumHourly?:  {
    __typename: "OldDatumHourly",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOldDatumHourlyMutationVariables = {
  input: UpdateOldDatumHourlyInput,
  condition?: ModelOldDatumHourlyConditionInput | null,
};

export type UpdateOldDatumHourlyMutation = {
  updateOldDatumHourly?:  {
    __typename: "OldDatumHourly",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOldDatumHourlyMutationVariables = {
  input: DeleteOldDatumHourlyInput,
  condition?: ModelOldDatumHourlyConditionInput | null,
};

export type DeleteOldDatumHourlyMutation = {
  deleteOldDatumHourly?:  {
    __typename: "OldDatumHourly",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOldDatumDailyMutationVariables = {
  input: CreateOldDatumDailyInput,
  condition?: ModelOldDatumDailyConditionInput | null,
};

export type CreateOldDatumDailyMutation = {
  createOldDatumDaily?:  {
    __typename: "OldDatumDaily",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOldDatumDailyMutationVariables = {
  input: UpdateOldDatumDailyInput,
  condition?: ModelOldDatumDailyConditionInput | null,
};

export type UpdateOldDatumDailyMutation = {
  updateOldDatumDaily?:  {
    __typename: "OldDatumDaily",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOldDatumDailyMutationVariables = {
  input: DeleteOldDatumDailyInput,
  condition?: ModelOldDatumDailyConditionInput | null,
};

export type DeleteOldDatumDailyMutation = {
  deleteOldDatumDaily?:  {
    __typename: "OldDatumDaily",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOperatingRatioMutationVariables = {
  input: CreateOperatingRatioInput,
  condition?: ModelOperatingRatioConditionInput | null,
};

export type CreateOperatingRatioMutation = {
  createOperatingRatio?:  {
    __typename: "OperatingRatio",
    id: string,
    nodeId: string,
    operatingDatumId: string,
    failureDatumId: string,
    operatingLabel: string,
    suspensionLabel: string,
    failureLabel: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOperatingRatioMutationVariables = {
  input: UpdateOperatingRatioInput,
  condition?: ModelOperatingRatioConditionInput | null,
};

export type UpdateOperatingRatioMutation = {
  updateOperatingRatio?:  {
    __typename: "OperatingRatio",
    id: string,
    nodeId: string,
    operatingDatumId: string,
    failureDatumId: string,
    operatingLabel: string,
    suspensionLabel: string,
    failureLabel: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOperatingRatioMutationVariables = {
  input: DeleteOperatingRatioInput,
  condition?: ModelOperatingRatioConditionInput | null,
};

export type DeleteOperatingRatioMutation = {
  deleteOperatingRatio?:  {
    __typename: "OperatingRatio",
    id: string,
    nodeId: string,
    operatingDatumId: string,
    failureDatumId: string,
    operatingLabel: string,
    suspensionLabel: string,
    failureLabel: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetCameraLiveStreamingUrlQueryVariables = {
  streamName: string,
};

export type GetCameraLiveStreamingUrlQuery = {
  getCameraLiveStreamingUrl:  {
    __typename: "GetCameraLiveStreamingUrlResult",
    url?: string | null,
    error?: string | null,
  },
};

export type GetEccMenuQueryVariables = {
  id: string,
};

export type GetEccMenuQuery = {
  getEccMenu?:  {
    __typename: "EccMenu",
    id: string,
    accountId: string,
    path: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEccMenusQueryVariables = {
  id?: string | null,
  filter?: ModelEccMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEccMenusQuery = {
  listEccMenus?:  {
    __typename: "ModelEccMenuConnection",
    items:  Array< {
      __typename: "EccMenu",
      id: string,
      accountId: string,
      path: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNodeTypeQueryVariables = {
  id: string,
};

export type GetNodeTypeQuery = {
  getNodeType?:  {
    __typename: "NodeType",
    id: string,
    name: string,
    typeIcon: Icon,
    imagePath?: string | null,
    mapImagePath?: string | null,
    mainDatumIndex?: number | null,
    reversedLabelColor?: boolean | null,
    permissions: Array< string >,
    inquiryChart?:  Array< {
      __typename: "InquiryChart",
      title: string,
      inquiries:  Array< {
        __typename: "Inquiry",
        id: string,
        title: string,
        description: string,
        image: ( {
            __typename: "InquiryImageHref",
            href: string,
          } | {
            __typename: "InquiryImageS3",
            s3Key: string,
          }
        ) | null,
        options:  Array< {
          __typename: "InquiryOption",
          title: string,
          next: string,
        } >,
      } >,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNodeTypesQueryVariables = {
  id?: string | null,
  filter?: ModelNodeTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNodeTypesQuery = {
  listNodeTypes?:  {
    __typename: "ModelNodeTypeConnection",
    items:  Array< {
      __typename: "NodeType",
      id: string,
      name: string,
      typeIcon: Icon,
      imagePath?: string | null,
      mapImagePath?: string | null,
      mainDatumIndex?: number | null,
      reversedLabelColor?: boolean | null,
      permissions: Array< string >,
      inquiryChart?:  Array< {
        __typename: "InquiryChart",
        title: string,
        inquiries:  Array< {
          __typename: "Inquiry",
          id: string,
          title: string,
          description: string,
          image: ( {
              __typename: "InquiryImageHref",
              href: string,
            } | {
              __typename: "InquiryImageS3",
              s3Key: string,
            }
          ) | null,
          options:  Array< {
            __typename: "InquiryOption",
            title: string,
            next: string,
          } >,
        } >,
      } > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTenantQueryVariables = {
  id: string,
};

export type GetTenantQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    permissions: Array< string >,
    iotDevices:  Array< {
      __typename: "IoTDevice",
      id: number,
      dataPoints: number,
      sendCycle: number,
      deployedAt?: string | null,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTenantsQueryVariables = {
  id?: string | null,
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTenantsQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      permissions: Array< string >,
      iotDevices:  Array< {
        __typename: "IoTDevice",
        id: number,
        dataPoints: number,
        sendCycle: number,
        deployedAt?: string | null,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWorkHistoryQueryVariables = {
  id: string,
};

export type GetWorkHistoryQuery = {
  getWorkHistory?:  {
    __typename: "WorkHistory",
    id: string,
    time: string,
    nodeId: string,
    workType: WorkType,
    workSummary: string,
    worker: string,
    workNumber?: string | null,
    positionX?: number | null,
    positionY?: number | null,
    positionZ?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWorkHistoriesQueryVariables = {
  id?: string | null,
  filter?: ModelWorkHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListWorkHistoriesQuery = {
  listWorkHistories?:  {
    __typename: "ModelWorkHistoryConnection",
    items:  Array< {
      __typename: "WorkHistory",
      id: string,
      time: string,
      nodeId: string,
      workType: WorkType,
      workSummary: string,
      worker: string,
      workNumber?: string | null,
      positionX?: number | null,
      positionY?: number | null,
      positionZ?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEccMenusByAccountIdQueryVariables = {
  accountId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEccMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEccMenusByAccountIdQuery = {
  listEccMenusByAccountId?:  {
    __typename: "ModelEccMenuConnection",
    items:  Array< {
      __typename: "EccMenu",
      id: string,
      accountId: string,
      path: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListWorkHistoryByNodeIdQueryVariables = {
  nodeId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkHistoryByNodeIdQuery = {
  listWorkHistoryByNodeId?:  {
    __typename: "ModelWorkHistoryConnection",
    items:  Array< {
      __typename: "WorkHistory",
      id: string,
      time: string,
      nodeId: string,
      workType: WorkType,
      workSummary: string,
      worker: string,
      workNumber?: string | null,
      positionX?: number | null,
      positionY?: number | null,
      positionZ?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListWorkHistoryByNodeIdAndTimeQueryVariables = {
  nodeId?: string | null,
  time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkHistoryByNodeIdAndTimeQuery = {
  listWorkHistoryByNodeIdAndTime?:  {
    __typename: "ModelWorkHistoryConnection",
    items:  Array< {
      __typename: "WorkHistory",
      id: string,
      time: string,
      nodeId: string,
      workType: WorkType,
      workSummary: string,
      worker: string,
      workNumber?: string | null,
      positionX?: number | null,
      positionY?: number | null,
      positionZ?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAgvPositionQueryVariables = {
  id: string,
};

export type GetAgvPositionQuery = {
  getAgvPosition?:  {
    __typename: "AgvPosition",
    id: string,
    nodeId: string,
    imagePath?: string | null,
    displayName: string,
    floor?: string | null,
    layout?: string | null,
    paperSize?: string | null,
    scale?: string | null,
    revisedPriceX?: number | null,
    revisedPriceY?: number | null,
    theta?: number | null,
    originX?: number | null,
    originY?: number | null,
    type: agvPositionType,
    datumTypeIds?:  Array< {
      __typename: "datumTypeIdsType",
      nodeId: string,
      xPositionDatumType: string,
      yPositionDatumType: string,
      floorDatumType: string,
      layoutDatumType: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAgvPositionsQueryVariables = {
  id?: string | null,
  filter?: ModelAgvPositionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAgvPositionsQuery = {
  listAgvPositions?:  {
    __typename: "ModelAgvPositionConnection",
    items:  Array< {
      __typename: "AgvPosition",
      id: string,
      nodeId: string,
      imagePath?: string | null,
      displayName: string,
      floor?: string | null,
      layout?: string | null,
      paperSize?: string | null,
      scale?: string | null,
      revisedPriceX?: number | null,
      revisedPriceY?: number | null,
      theta?: number | null,
      originX?: number | null,
      originY?: number | null,
      type: agvPositionType,
      datumTypeIds?:  Array< {
        __typename: "datumTypeIdsType",
        nodeId: string,
        xPositionDatumType: string,
        yPositionDatumType: string,
        floorDatumType: string,
        layoutDatumType: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAgvPositionByNodeIdQueryVariables = {
  nodeId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAgvPositionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgvPositionByNodeIdQuery = {
  listAgvPositionByNodeId?:  {
    __typename: "ModelAgvPositionConnection",
    items:  Array< {
      __typename: "AgvPosition",
      id: string,
      nodeId: string,
      imagePath?: string | null,
      displayName: string,
      floor?: string | null,
      layout?: string | null,
      paperSize?: string | null,
      scale?: string | null,
      revisedPriceX?: number | null,
      revisedPriceY?: number | null,
      theta?: number | null,
      originX?: number | null,
      originY?: number | null,
      type: agvPositionType,
      datumTypeIds?:  Array< {
        __typename: "datumTypeIdsType",
        nodeId: string,
        xPositionDatumType: string,
        yPositionDatumType: string,
        floorDatumType: string,
        layoutDatumType: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDatumTypeQueryVariables = {
  id: string,
};

export type GetDatumTypeQuery = {
  getDatumType?:  {
    __typename: "DatumType",
    id: string,
    nodeTypeId: string,
    index: number,
    name: string,
    labels?:  Array< {
      __typename: "LabelInfo",
      message: string,
      type: EventType,
    } > | null,
    type?: ValueType | null,
    unit?: string | null,
    digits?: number | null,
    valueMax?: number | null,
    valueMin?: number | null,
    limitHigh?: number | null,
    limitLow?: number | null,
    timeoutInSec?: number | null,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDatumTypesQueryVariables = {
  id?: string | null,
  filter?: ModelDatumTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDatumTypesQuery = {
  listDatumTypes?:  {
    __typename: "ModelDatumTypeConnection",
    items:  Array< {
      __typename: "DatumType",
      id: string,
      nodeTypeId: string,
      index: number,
      name: string,
      labels?:  Array< {
        __typename: "LabelInfo",
        message: string,
        type: EventType,
      } > | null,
      type?: ValueType | null,
      unit?: string | null,
      digits?: number | null,
      valueMax?: number | null,
      valueMin?: number | null,
      limitHigh?: number | null,
      limitLow?: number | null,
      timeoutInSec?: number | null,
      permissions: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDatumTypesByNodeTypeIdQueryVariables = {
  nodeTypeId?: string | null,
  index?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDatumTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDatumTypesByNodeTypeIdQuery = {
  listDatumTypesByNodeTypeId?:  {
    __typename: "ModelDatumTypeConnection",
    items:  Array< {
      __typename: "DatumType",
      id: string,
      nodeTypeId: string,
      index: number,
      name: string,
      labels?:  Array< {
        __typename: "LabelInfo",
        message: string,
        type: EventType,
      } > | null,
      type?: ValueType | null,
      unit?: string | null,
      digits?: number | null,
      valueMax?: number | null,
      valueMin?: number | null,
      limitHigh?: number | null,
      limitLow?: number | null,
      timeoutInSec?: number | null,
      permissions: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDatumQueryVariables = {
  id: string,
};

export type GetDatumQuery = {
  getDatum?:  {
    __typename: "Datum",
    id: string,
    nodeId: string,
    typeId: string,
    time: string,
    value?: string | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDataQueryVariables = {
  id?: string | null,
  filter?: ModelDatumFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDataQuery = {
  listData?:  {
    __typename: "ModelDatumConnection",
    items:  Array< {
      __typename: "Datum",
      id: string,
      nodeId: string,
      typeId: string,
      time: string,
      value?: string | null,
      status: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDatumsByNodeIdTypeIdQueryVariables = {
  nodeId?: string | null,
  typeId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDatumFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDatumsByNodeIdTypeIdQuery = {
  listDatumsByNodeIdTypeId?:  {
    __typename: "ModelDatumConnection",
    items:  Array< {
      __typename: "Datum",
      id: string,
      nodeId: string,
      typeId: string,
      time: string,
      value?: string | null,
      status: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDatumsByTypeIdQueryVariables = {
  typeId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDatumFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDatumsByTypeIdQuery = {
  listDatumsByTypeId?:  {
    __typename: "ModelDatumConnection",
    items:  Array< {
      __typename: "Datum",
      id: string,
      nodeId: string,
      typeId: string,
      time: string,
      value?: string | null,
      status: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventlogQueryVariables = {
  id: string,
};

export type GetEventlogQuery = {
  getEventlog?:  {
    __typename: "Eventlog",
    id: string,
    time: string,
    nodeId: string,
    datumId: string,
    datumName: string,
    type: EventType,
    event: string,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEventlogsQueryVariables = {
  filter?: ModelEventlogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventlogsQuery = {
  listEventlogs?:  {
    __typename: "ModelEventlogConnection",
    items:  Array< {
      __typename: "Eventlog",
      id: string,
      time: string,
      nodeId: string,
      datumId: string,
      datumName: string,
      type: EventType,
      event: string,
      permissions: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEventlogsByNodeIdQueryVariables = {
  nodeId?: string | null,
  time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventlogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventlogsByNodeIdQuery = {
  listEventlogsByNodeId?:  {
    __typename: "ModelEventlogConnection",
    items:  Array< {
      __typename: "Eventlog",
      id: string,
      time: string,
      nodeId: string,
      datumId: string,
      datumName: string,
      type: EventType,
      event: string,
      permissions: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEventlogsByDatumIdQueryVariables = {
  datumId?: string | null,
  time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventlogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventlogsByDatumIdQuery = {
  listEventlogsByDatumId?:  {
    __typename: "ModelEventlogConnection",
    items:  Array< {
      __typename: "Eventlog",
      id: string,
      time: string,
      nodeId: string,
      datumId: string,
      datumName: string,
      type: EventType,
      event: string,
      permissions: Array< string >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNodeSecretQueryVariables = {
  id: string,
};

export type GetNodeSecretQuery = {
  getNodeSecret?:  {
    __typename: "NodeSecret",
    id: string,
    camera?:  {
      __typename: "CameraSecretInfo",
      origin: string,
      username: string,
      password: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNodeSecretsQueryVariables = {
  id?: string | null,
  filter?: ModelNodeSecretFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNodeSecretsQuery = {
  listNodeSecrets?:  {
    __typename: "ModelNodeSecretConnection",
    items:  Array< {
      __typename: "NodeSecret",
      id: string,
      camera?:  {
        __typename: "CameraSecretInfo",
        origin: string,
        username: string,
        password: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNodeQueryVariables = {
  id: string,
};

export type GetNodeQuery = {
  getNode?:  {
    __typename: "Node",
    id: string,
    parentId: string,
    typeId: string,
    name: string,
    imagePath?: string | null,
    featureInfos:  Array< {
      __typename: "FeatureInfo",
      label: string,
      type: string,
    } >,
    permissions: Array< string >,
    treeIndex?: number | null,
    account: string,
    isWarning?: boolean | null,
    specifications?:  Array< {
      __typename: "Specification",
      label: string,
      value: string,
    } > | null,
    graphic?:  {
      __typename: "GraphicInfo",
      modelPath: string,
      cameraPositionX?: number | null,
      cameraPositionY?: number | null,
      cameraPositionZ?: number | null,
    } | null,
    camera?:  {
      __typename: "CameraInfo",
      controllable?: boolean | null,
      statusDatumId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNodesQueryVariables = {
  id?: string | null,
  filter?: ModelNodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNodesQuery = {
  listNodes?:  {
    __typename: "ModelNodeConnection",
    items:  Array< {
      __typename: "Node",
      id: string,
      parentId: string,
      typeId: string,
      name: string,
      imagePath?: string | null,
      featureInfos:  Array< {
        __typename: "FeatureInfo",
        label: string,
        type: string,
      } >,
      permissions: Array< string >,
      treeIndex?: number | null,
      account: string,
      isWarning?: boolean | null,
      specifications?:  Array< {
        __typename: "Specification",
        label: string,
        value: string,
      } > | null,
      graphic?:  {
        __typename: "GraphicInfo",
        modelPath: string,
        cameraPositionX?: number | null,
        cameraPositionY?: number | null,
        cameraPositionZ?: number | null,
      } | null,
      camera?:  {
        __typename: "CameraInfo",
        controllable?: boolean | null,
        statusDatumId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNodesByParentIdQueryVariables = {
  parentId?: string | null,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNodesByParentIdQuery = {
  listNodesByParentId?:  {
    __typename: "ModelNodeConnection",
    items:  Array< {
      __typename: "Node",
      id: string,
      parentId: string,
      typeId: string,
      name: string,
      imagePath?: string | null,
      featureInfos:  Array< {
        __typename: "FeatureInfo",
        label: string,
        type: string,
      } >,
      permissions: Array< string >,
      treeIndex?: number | null,
      account: string,
      isWarning?: boolean | null,
      specifications?:  Array< {
        __typename: "Specification",
        label: string,
        value: string,
      } > | null,
      graphic?:  {
        __typename: "GraphicInfo",
        modelPath: string,
        cameraPositionX?: number | null,
        cameraPositionY?: number | null,
        cameraPositionZ?: number | null,
      } | null,
      camera?:  {
        __typename: "CameraInfo",
        controllable?: boolean | null,
        statusDatumId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNodesByAccountQueryVariables = {
  account?: string | null,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNodesByAccountQuery = {
  listNodesByAccount?:  {
    __typename: "ModelNodeConnection",
    items:  Array< {
      __typename: "Node",
      id: string,
      parentId: string,
      typeId: string,
      name: string,
      imagePath?: string | null,
      featureInfos:  Array< {
        __typename: "FeatureInfo",
        label: string,
        type: string,
      } >,
      permissions: Array< string >,
      treeIndex?: number | null,
      account: string,
      isWarning?: boolean | null,
      specifications?:  Array< {
        __typename: "Specification",
        label: string,
        value: string,
      } > | null,
      graphic?:  {
        __typename: "GraphicInfo",
        modelPath: string,
        cameraPositionX?: number | null,
        cameraPositionY?: number | null,
        cameraPositionZ?: number | null,
      } | null,
      camera?:  {
        __typename: "CameraInfo",
        controllable?: boolean | null,
        statusDatumId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOldDatumQueryVariables = {
  id: string,
};

export type GetOldDatumQuery = {
  getOldDatum?:  {
    __typename: "OldDatum",
    id: string,
    datumId: string,
    time: string,
    value?: number | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOldDataQueryVariables = {
  id?: string | null,
  filter?: ModelOldDatumFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOldDataQuery = {
  listOldData?:  {
    __typename: "ModelOldDatumConnection",
    items:  Array< {
      __typename: "OldDatum",
      id: string,
      datumId: string,
      time: string,
      value?: number | null,
      status: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOldDataByDatumIdQueryVariables = {
  datumId?: string | null,
  time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOldDatumFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOldDataByDatumIdQuery = {
  listOldDataByDatumId?:  {
    __typename: "ModelOldDatumConnection",
    items:  Array< {
      __typename: "OldDatum",
      id: string,
      datumId: string,
      time: string,
      value?: number | null,
      status: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOldDatumHourlyQueryVariables = {
  id: string,
};

export type GetOldDatumHourlyQuery = {
  getOldDatumHourly?:  {
    __typename: "OldDatumHourly",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOldDatumHourliesQueryVariables = {
  id?: string | null,
  filter?: ModelOldDatumHourlyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOldDatumHourliesQuery = {
  listOldDatumHourlies?:  {
    __typename: "ModelOldDatumHourlyConnection",
    items:  Array< {
      __typename: "OldDatumHourly",
      id: string,
      datumId: string,
      time: string,
      value?:  {
        __typename: "Statistics",
        max: number,
        mean: number,
        mid: number,
        min: number,
      } | null,
      count: number,
      total: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOldDataHourlyByDatumIdQueryVariables = {
  datumId?: string | null,
  time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOldDatumHourlyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOldDataHourlyByDatumIdQuery = {
  listOldDataHourlyByDatumId?:  {
    __typename: "ModelOldDatumHourlyConnection",
    items:  Array< {
      __typename: "OldDatumHourly",
      id: string,
      datumId: string,
      time: string,
      value?:  {
        __typename: "Statistics",
        max: number,
        mean: number,
        mid: number,
        min: number,
      } | null,
      count: number,
      total: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOldDatumDailyQueryVariables = {
  id: string,
};

export type GetOldDatumDailyQuery = {
  getOldDatumDaily?:  {
    __typename: "OldDatumDaily",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOldDatumDailiesQueryVariables = {
  id?: string | null,
  filter?: ModelOldDatumDailyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOldDatumDailiesQuery = {
  listOldDatumDailies?:  {
    __typename: "ModelOldDatumDailyConnection",
    items:  Array< {
      __typename: "OldDatumDaily",
      id: string,
      datumId: string,
      time: string,
      value?:  {
        __typename: "Statistics",
        max: number,
        mean: number,
        mid: number,
        min: number,
      } | null,
      count: number,
      total: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOldDataDailyByDatumIdQueryVariables = {
  datumId?: string | null,
  time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOldDatumDailyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOldDataDailyByDatumIdQuery = {
  listOldDataDailyByDatumId?:  {
    __typename: "ModelOldDatumDailyConnection",
    items:  Array< {
      __typename: "OldDatumDaily",
      id: string,
      datumId: string,
      time: string,
      value?:  {
        __typename: "Statistics",
        max: number,
        mean: number,
        mid: number,
        min: number,
      } | null,
      count: number,
      total: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOperatingRatioQueryVariables = {
  id: string,
};

export type GetOperatingRatioQuery = {
  getOperatingRatio?:  {
    __typename: "OperatingRatio",
    id: string,
    nodeId: string,
    operatingDatumId: string,
    failureDatumId: string,
    operatingLabel: string,
    suspensionLabel: string,
    failureLabel: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOperatingRatiosQueryVariables = {
  id?: string | null,
  filter?: ModelOperatingRatioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOperatingRatiosQuery = {
  listOperatingRatios?:  {
    __typename: "ModelOperatingRatioConnection",
    items:  Array< {
      __typename: "OperatingRatio",
      id: string,
      nodeId: string,
      operatingDatumId: string,
      failureDatumId: string,
      operatingLabel: string,
      suspensionLabel: string,
      failureLabel: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOperatingRatioByNodeIdQueryVariables = {
  nodeId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOperatingRatioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOperatingRatioByNodeIdQuery = {
  listOperatingRatioByNodeId?:  {
    __typename: "ModelOperatingRatioConnection",
    items:  Array< {
      __typename: "OperatingRatio",
      id: string,
      nodeId: string,
      operatingDatumId: string,
      failureDatumId: string,
      operatingLabel: string,
      suspensionLabel: string,
      failureLabel: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnUpdateDatumByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateDatumByIdSubscription = {
  onUpdateDatumById?:  {
    __typename: "Datum",
    id: string,
    nodeId: string,
    typeId: string,
    time: string,
    value?: string | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDatumByNodeIdSubscriptionVariables = {
  nodeId: string,
};

export type OnUpdateDatumByNodeIdSubscription = {
  onUpdateDatumByNodeId?:  {
    __typename: "Datum",
    id: string,
    nodeId: string,
    typeId: string,
    time: string,
    value?: string | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOldDatumByDatumIdSubscriptionVariables = {
  datumId: string,
};

export type OnCreateOldDatumByDatumIdSubscription = {
  onCreateOldDatumByDatumId?:  {
    __typename: "OldDatum",
    id: string,
    datumId: string,
    time: string,
    value?: number | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOldDatumByDatumIdSubscriptionVariables = {
  datumId: string,
};

export type OnUpdateOldDatumByDatumIdSubscription = {
  onUpdateOldDatumByDatumId?:  {
    __typename: "OldDatum",
    id: string,
    datumId: string,
    time: string,
    value?: number | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEccMenuSubscription = {
  onCreateEccMenu?:  {
    __typename: "EccMenu",
    id: string,
    accountId: string,
    path: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEccMenuSubscription = {
  onUpdateEccMenu?:  {
    __typename: "EccMenu",
    id: string,
    accountId: string,
    path: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEccMenuSubscription = {
  onDeleteEccMenu?:  {
    __typename: "EccMenu",
    id: string,
    accountId: string,
    path: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNodeTypeSubscription = {
  onCreateNodeType?:  {
    __typename: "NodeType",
    id: string,
    name: string,
    typeIcon: Icon,
    imagePath?: string | null,
    mapImagePath?: string | null,
    mainDatumIndex?: number | null,
    reversedLabelColor?: boolean | null,
    permissions: Array< string >,
    inquiryChart?:  Array< {
      __typename: "InquiryChart",
      title: string,
      inquiries:  Array< {
        __typename: "Inquiry",
        id: string,
        title: string,
        description: string,
        image: ( {
            __typename: "InquiryImageHref",
            href: string,
          } | {
            __typename: "InquiryImageS3",
            s3Key: string,
          }
        ) | null,
        options:  Array< {
          __typename: "InquiryOption",
          title: string,
          next: string,
        } >,
      } >,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNodeTypeSubscription = {
  onUpdateNodeType?:  {
    __typename: "NodeType",
    id: string,
    name: string,
    typeIcon: Icon,
    imagePath?: string | null,
    mapImagePath?: string | null,
    mainDatumIndex?: number | null,
    reversedLabelColor?: boolean | null,
    permissions: Array< string >,
    inquiryChart?:  Array< {
      __typename: "InquiryChart",
      title: string,
      inquiries:  Array< {
        __typename: "Inquiry",
        id: string,
        title: string,
        description: string,
        image: ( {
            __typename: "InquiryImageHref",
            href: string,
          } | {
            __typename: "InquiryImageS3",
            s3Key: string,
          }
        ) | null,
        options:  Array< {
          __typename: "InquiryOption",
          title: string,
          next: string,
        } >,
      } >,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNodeTypeSubscription = {
  onDeleteNodeType?:  {
    __typename: "NodeType",
    id: string,
    name: string,
    typeIcon: Icon,
    imagePath?: string | null,
    mapImagePath?: string | null,
    mainDatumIndex?: number | null,
    reversedLabelColor?: boolean | null,
    permissions: Array< string >,
    inquiryChart?:  Array< {
      __typename: "InquiryChart",
      title: string,
      inquiries:  Array< {
        __typename: "Inquiry",
        id: string,
        title: string,
        description: string,
        image: ( {
            __typename: "InquiryImageHref",
            href: string,
          } | {
            __typename: "InquiryImageS3",
            s3Key: string,
          }
        ) | null,
        options:  Array< {
          __typename: "InquiryOption",
          title: string,
          next: string,
        } >,
      } >,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTenantSubscription = {
  onCreateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    permissions: Array< string >,
    iotDevices:  Array< {
      __typename: "IoTDevice",
      id: number,
      dataPoints: number,
      sendCycle: number,
      deployedAt?: string | null,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTenantSubscription = {
  onUpdateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    permissions: Array< string >,
    iotDevices:  Array< {
      __typename: "IoTDevice",
      id: number,
      dataPoints: number,
      sendCycle: number,
      deployedAt?: string | null,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTenantSubscription = {
  onDeleteTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    permissions: Array< string >,
    iotDevices:  Array< {
      __typename: "IoTDevice",
      id: number,
      dataPoints: number,
      sendCycle: number,
      deployedAt?: string | null,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateWorkHistorySubscription = {
  onCreateWorkHistory?:  {
    __typename: "WorkHistory",
    id: string,
    time: string,
    nodeId: string,
    workType: WorkType,
    workSummary: string,
    worker: string,
    workNumber?: string | null,
    positionX?: number | null,
    positionY?: number | null,
    positionZ?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWorkHistorySubscription = {
  onUpdateWorkHistory?:  {
    __typename: "WorkHistory",
    id: string,
    time: string,
    nodeId: string,
    workType: WorkType,
    workSummary: string,
    worker: string,
    workNumber?: string | null,
    positionX?: number | null,
    positionY?: number | null,
    positionZ?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWorkHistorySubscription = {
  onDeleteWorkHistory?:  {
    __typename: "WorkHistory",
    id: string,
    time: string,
    nodeId: string,
    workType: WorkType,
    workSummary: string,
    worker: string,
    workNumber?: string | null,
    positionX?: number | null,
    positionY?: number | null,
    positionZ?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAgvPositionSubscription = {
  onCreateAgvPosition?:  {
    __typename: "AgvPosition",
    id: string,
    nodeId: string,
    imagePath?: string | null,
    displayName: string,
    floor?: string | null,
    layout?: string | null,
    paperSize?: string | null,
    scale?: string | null,
    revisedPriceX?: number | null,
    revisedPriceY?: number | null,
    theta?: number | null,
    originX?: number | null,
    originY?: number | null,
    type: agvPositionType,
    datumTypeIds?:  Array< {
      __typename: "datumTypeIdsType",
      nodeId: string,
      xPositionDatumType: string,
      yPositionDatumType: string,
      floorDatumType: string,
      layoutDatumType: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAgvPositionSubscription = {
  onUpdateAgvPosition?:  {
    __typename: "AgvPosition",
    id: string,
    nodeId: string,
    imagePath?: string | null,
    displayName: string,
    floor?: string | null,
    layout?: string | null,
    paperSize?: string | null,
    scale?: string | null,
    revisedPriceX?: number | null,
    revisedPriceY?: number | null,
    theta?: number | null,
    originX?: number | null,
    originY?: number | null,
    type: agvPositionType,
    datumTypeIds?:  Array< {
      __typename: "datumTypeIdsType",
      nodeId: string,
      xPositionDatumType: string,
      yPositionDatumType: string,
      floorDatumType: string,
      layoutDatumType: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAgvPositionSubscription = {
  onDeleteAgvPosition?:  {
    __typename: "AgvPosition",
    id: string,
    nodeId: string,
    imagePath?: string | null,
    displayName: string,
    floor?: string | null,
    layout?: string | null,
    paperSize?: string | null,
    scale?: string | null,
    revisedPriceX?: number | null,
    revisedPriceY?: number | null,
    theta?: number | null,
    originX?: number | null,
    originY?: number | null,
    type: agvPositionType,
    datumTypeIds?:  Array< {
      __typename: "datumTypeIdsType",
      nodeId: string,
      xPositionDatumType: string,
      yPositionDatumType: string,
      floorDatumType: string,
      layoutDatumType: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDatumTypeSubscription = {
  onCreateDatumType?:  {
    __typename: "DatumType",
    id: string,
    nodeTypeId: string,
    index: number,
    name: string,
    labels?:  Array< {
      __typename: "LabelInfo",
      message: string,
      type: EventType,
    } > | null,
    type?: ValueType | null,
    unit?: string | null,
    digits?: number | null,
    valueMax?: number | null,
    valueMin?: number | null,
    limitHigh?: number | null,
    limitLow?: number | null,
    timeoutInSec?: number | null,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDatumTypeSubscription = {
  onUpdateDatumType?:  {
    __typename: "DatumType",
    id: string,
    nodeTypeId: string,
    index: number,
    name: string,
    labels?:  Array< {
      __typename: "LabelInfo",
      message: string,
      type: EventType,
    } > | null,
    type?: ValueType | null,
    unit?: string | null,
    digits?: number | null,
    valueMax?: number | null,
    valueMin?: number | null,
    limitHigh?: number | null,
    limitLow?: number | null,
    timeoutInSec?: number | null,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDatumTypeSubscription = {
  onDeleteDatumType?:  {
    __typename: "DatumType",
    id: string,
    nodeTypeId: string,
    index: number,
    name: string,
    labels?:  Array< {
      __typename: "LabelInfo",
      message: string,
      type: EventType,
    } > | null,
    type?: ValueType | null,
    unit?: string | null,
    digits?: number | null,
    valueMax?: number | null,
    valueMin?: number | null,
    limitHigh?: number | null,
    limitLow?: number | null,
    timeoutInSec?: number | null,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDatumSubscription = {
  onCreateDatum?:  {
    __typename: "Datum",
    id: string,
    nodeId: string,
    typeId: string,
    time: string,
    value?: string | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDatumSubscription = {
  onUpdateDatum?:  {
    __typename: "Datum",
    id: string,
    nodeId: string,
    typeId: string,
    time: string,
    value?: string | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDatumSubscription = {
  onDeleteDatum?:  {
    __typename: "Datum",
    id: string,
    nodeId: string,
    typeId: string,
    time: string,
    value?: string | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEventlogSubscription = {
  onCreateEventlog?:  {
    __typename: "Eventlog",
    id: string,
    time: string,
    nodeId: string,
    datumId: string,
    datumName: string,
    type: EventType,
    event: string,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEventlogSubscription = {
  onUpdateEventlog?:  {
    __typename: "Eventlog",
    id: string,
    time: string,
    nodeId: string,
    datumId: string,
    datumName: string,
    type: EventType,
    event: string,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEventlogSubscription = {
  onDeleteEventlog?:  {
    __typename: "Eventlog",
    id: string,
    time: string,
    nodeId: string,
    datumId: string,
    datumName: string,
    type: EventType,
    event: string,
    permissions: Array< string >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNodeSecretSubscription = {
  onCreateNodeSecret?:  {
    __typename: "NodeSecret",
    id: string,
    camera?:  {
      __typename: "CameraSecretInfo",
      origin: string,
      username: string,
      password: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNodeSecretSubscription = {
  onUpdateNodeSecret?:  {
    __typename: "NodeSecret",
    id: string,
    camera?:  {
      __typename: "CameraSecretInfo",
      origin: string,
      username: string,
      password: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNodeSecretSubscription = {
  onDeleteNodeSecret?:  {
    __typename: "NodeSecret",
    id: string,
    camera?:  {
      __typename: "CameraSecretInfo",
      origin: string,
      username: string,
      password: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNodeSubscription = {
  onCreateNode?:  {
    __typename: "Node",
    id: string,
    parentId: string,
    typeId: string,
    name: string,
    imagePath?: string | null,
    featureInfos:  Array< {
      __typename: "FeatureInfo",
      label: string,
      type: string,
    } >,
    permissions: Array< string >,
    treeIndex?: number | null,
    account: string,
    isWarning?: boolean | null,
    specifications?:  Array< {
      __typename: "Specification",
      label: string,
      value: string,
    } > | null,
    graphic?:  {
      __typename: "GraphicInfo",
      modelPath: string,
      cameraPositionX?: number | null,
      cameraPositionY?: number | null,
      cameraPositionZ?: number | null,
    } | null,
    camera?:  {
      __typename: "CameraInfo",
      controllable?: boolean | null,
      statusDatumId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNodeSubscription = {
  onUpdateNode?:  {
    __typename: "Node",
    id: string,
    parentId: string,
    typeId: string,
    name: string,
    imagePath?: string | null,
    featureInfos:  Array< {
      __typename: "FeatureInfo",
      label: string,
      type: string,
    } >,
    permissions: Array< string >,
    treeIndex?: number | null,
    account: string,
    isWarning?: boolean | null,
    specifications?:  Array< {
      __typename: "Specification",
      label: string,
      value: string,
    } > | null,
    graphic?:  {
      __typename: "GraphicInfo",
      modelPath: string,
      cameraPositionX?: number | null,
      cameraPositionY?: number | null,
      cameraPositionZ?: number | null,
    } | null,
    camera?:  {
      __typename: "CameraInfo",
      controllable?: boolean | null,
      statusDatumId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNodeSubscription = {
  onDeleteNode?:  {
    __typename: "Node",
    id: string,
    parentId: string,
    typeId: string,
    name: string,
    imagePath?: string | null,
    featureInfos:  Array< {
      __typename: "FeatureInfo",
      label: string,
      type: string,
    } >,
    permissions: Array< string >,
    treeIndex?: number | null,
    account: string,
    isWarning?: boolean | null,
    specifications?:  Array< {
      __typename: "Specification",
      label: string,
      value: string,
    } > | null,
    graphic?:  {
      __typename: "GraphicInfo",
      modelPath: string,
      cameraPositionX?: number | null,
      cameraPositionY?: number | null,
      cameraPositionZ?: number | null,
    } | null,
    camera?:  {
      __typename: "CameraInfo",
      controllable?: boolean | null,
      statusDatumId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOldDatumSubscription = {
  onCreateOldDatum?:  {
    __typename: "OldDatum",
    id: string,
    datumId: string,
    time: string,
    value?: number | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOldDatumSubscription = {
  onUpdateOldDatum?:  {
    __typename: "OldDatum",
    id: string,
    datumId: string,
    time: string,
    value?: number | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOldDatumSubscription = {
  onDeleteOldDatum?:  {
    __typename: "OldDatum",
    id: string,
    datumId: string,
    time: string,
    value?: number | null,
    status: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOldDatumHourlySubscription = {
  onCreateOldDatumHourly?:  {
    __typename: "OldDatumHourly",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOldDatumHourlySubscription = {
  onUpdateOldDatumHourly?:  {
    __typename: "OldDatumHourly",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOldDatumHourlySubscription = {
  onDeleteOldDatumHourly?:  {
    __typename: "OldDatumHourly",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOldDatumDailySubscription = {
  onCreateOldDatumDaily?:  {
    __typename: "OldDatumDaily",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOldDatumDailySubscription = {
  onUpdateOldDatumDaily?:  {
    __typename: "OldDatumDaily",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOldDatumDailySubscription = {
  onDeleteOldDatumDaily?:  {
    __typename: "OldDatumDaily",
    id: string,
    datumId: string,
    time: string,
    value?:  {
      __typename: "Statistics",
      max: number,
      mean: number,
      mid: number,
      min: number,
    } | null,
    count: number,
    total: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOperatingRatioSubscription = {
  onCreateOperatingRatio?:  {
    __typename: "OperatingRatio",
    id: string,
    nodeId: string,
    operatingDatumId: string,
    failureDatumId: string,
    operatingLabel: string,
    suspensionLabel: string,
    failureLabel: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOperatingRatioSubscription = {
  onUpdateOperatingRatio?:  {
    __typename: "OperatingRatio",
    id: string,
    nodeId: string,
    operatingDatumId: string,
    failureDatumId: string,
    operatingLabel: string,
    suspensionLabel: string,
    failureLabel: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOperatingRatioSubscription = {
  onDeleteOperatingRatio?:  {
    __typename: "OperatingRatio",
    id: string,
    nodeId: string,
    operatingDatumId: string,
    failureDatumId: string,
    operatingLabel: string,
    suspensionLabel: string,
    failureLabel: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
